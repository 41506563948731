import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './containers/HomePage';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6ebaf2',
    },
    secondary: {
      main: '#6EF2E8',
    },
    background: {
      paper: '#fafaff',
      default: '#fff',
    },
  },
});


function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div>
          <Route exact path="/" component={HomePage} />
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
