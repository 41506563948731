import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import brandLogo from '../../images/brand.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 'auto',
    height: 70,
    marginTop: theme.spacing(3),
    cursor: 'pointer',
  },
  layout: {
    padding: theme.spacing(4),
  },
}));

function HomePage() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <img src={brandLogo} className={classes.logo} alt="logo" />
      <div className={classes.layout}>
        <Typography variant="h6" align="center" color="textSecondary" gutterBottom>
          A research initiative focused on understanding consciousness
        </Typography>
      </div>
    </Grid>
  );
}

export default HomePage;
